.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.login-email-field{
  width: 279px;
}
.custom-input-field{
  width: 279px;
}
.custom-select-box{
  width: 279px;
}
.create-user-input{
  margin-left: 0%;
}
.create-user-save{
  margin-left: 45%;
}
.save-manage-appointment{
  margin: 0 0 20px 45% !important;
}
.disabled-text{
  color: rgba(0, 0, 0, 0.54);
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
input[type=number] {
	-moz-appearance: textfield;
  }